import React from 'react';
import { useNavigate } from 'react-router-dom';
import NumberIcon from './NumberIcon';

const HomePage = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full p-4">
      {/* Hero Section */}
      <div className="h-auto md:h-[80vh] flex items-center justify-center">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 py-8 mt-8">
          <div className="z-10 p-5 mt-20 md:mt-0 md:p-8">
            <h1 className="text-3xl md:text-4xl font-bold text-blue-500">
              Create Eye-Catching ATS Friendly Resumes & Cover Letters with
              Talium
            </h1>
            <p className="text-xl md:text-2xl mt-4 text-slate-600">
              Craft stunning, ATS-friendly resumes and personalized cover
              letters that highlight your skills and experiences.
            </p>
            <button
              onClick={() => navigate('/profile')}
              className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              aria-label="Get Started"
            >
              Start Now
            </button>
          </div>
          <div
            className="transition-all duration-500 block rounded-lg aspect-video bg-center bg-no-repeat bg-contain"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/images/LandingIntroPic.png)`,
            }}
            aria-label="Landing Intro Image"
          />
        </div>
      </div>

      <div className="bg-white rounded-xl">
        {/* Feature 1: Professional Resumes */}
        <section className="flex flex-row items-start justify-center p-6 my-8">
          <NumberIcon number={1} />
          <div className="flex-1">
            <div className="text-sm text-blue-500 font-bold mb-2">
              PROFESSIONAL RESUMES
            </div>
            <h2 className="text-3xl mb-4 text-black">
              Craft Resumes That Stand Out
            </h2>
            <p className="text-lg text-slate-600">
              Talium makes it simple to design visually appealing, ATS-friendly
              resumes that capture attention. Showcase your unique experiences
              and skills in a clean, professional format.
            </p>
          </div>
        </section>

        <hr className="border-b-2 border-slate-200 w-[80%] my-8 mx-auto" />

        {/* Feature 2: Cover Letter Creation */}
        <section className="flex flex-row items-start justify-center p-6 my-8">
          <NumberIcon number={2} />
          <div className="flex-1">
            <div className="text-sm text-blue-500 font-bold mb-2">
              PERSONALIZED COVER LETTERS
            </div>
            <h2 className="text-3xl mb-4 text-black">
              Create Impactful Cover Letters
            </h2>
            <p className="text-lg text-slate-600">
              Stand out with tailored cover letters designed to complement your
              resume. Highlight your strengths and align your experiences with
              the job role, ensuring a strong first impression.
            </p>
          </div>
        </section>

        <hr className="border-b-2 border-slate-200 w-[80%] my-8 mx-auto" />

        {/* Feature 3: User-Friendly Interface */}
        <section className="flex flex-row items-start justify-center p-6 my-8">
          <NumberIcon number={3} />
          <div className="flex-1">
            <div className="text-sm text-blue-500 font-bold mb-2">
              USER-FRIENDLY INTERFACE
            </div>
            <h2 className="text-3xl mb-4 text-black">
              Easy-to-Use Resume & Cover Letter Builder
            </h2>
            <p className="text-lg text-slate-600">
              Build resumes and cover letters effortlessly with our intuitive
              platform. Talium guides you step by step to create polished,
              job-ready documents that get noticed.
            </p>
          </div>
        </section>

        <hr className="border-b-2 border-slate-200 w-[80%] my-8 mx-auto" />

        {/* Feature 4: Tailored Templates */}
        <section className="flex flex-row items-start justify-center p-6 my-8">
          <NumberIcon number={4} />
          <div className="flex-1">
            <div className="text-sm text-blue-500 font-bold mb-2">
              MODERN TEMPLATES
            </div>
            <h2 className="text-3xl mb-4 text-black">
              Choose Customizable, ATS-Friendly Templates
            </h2>
            <p className="text-lg text-slate-600">
              Select from a variety of modern, ATS-friendly templates designed
              to showcase your experience and personality. Customize every
              detail to match your unique style and career goals.
            </p>
          </div>
        </section>
      </div>

      {/* Call-to-Action */}
      <div>
        <section className="flex flex-col items-center justify-center px-4 my-8 bg-white rounded-lg p-6">
          <div className="text-sm text-blue-500 font-bold mb-2">
            GET STARTED WITH TALIUM
          </div>
          <h2 className="text-3xl mb-4 text-center text-black">
            Build Resumes & Cover Letters That Leave a Lasting Impression
          </h2>
          <div
            className="w-full md:w-1/2 mb-4 rounded-lg aspect-video bg-center bg-no-repeat bg-cover"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/images/Image3.png)`,
            }}
            aria-label="Get Started Image"
          />
          <button
            onClick={() => navigate('/profile')}
            className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            aria-label="Get Started"
          >
            Start Now
          </button>
        </section>
      </div>
    </div>
  );
};

export default HomePage;
