import React from 'react';

const Post2 = () => {
  return (
    <div className="max-w-4xl mx-auto p-4">
      <img
        src="/images/Image2.png"
        alt="Our Purpose"
        className="w-full h-64 object-cover rounded-lg shadow-md"
        loading="lazy"
      />

      <div className="mt-6">
        <h1 className="text-4xl font-bold text-gray-800">Our Purpose</h1>
      </div>

      <div className="mt-6 text-gray-700 space-y-4">
        <p>
          At Talium, our purpose is to simplify the job application process by
          empowering users to create professional, ATS-friendly resumes and
          personalized cover letters effortlessly. We understand the challenges
          job seekers face when crafting standout application materials, and our
          mission is to provide tools that streamline this process, saving time
          and increasing your chances of success.
        </p>
        <h2 className="text-2xl font-semibold text-gray-800 mt-4">
          Crafting Professional Application Materials
        </h2>
        <p>
          In today&apos;s competitive job market, making a strong first
          impression is essential. Talium equips you with intuitive tools to
          create polished, ATS-optimized resumes and tailored cover letters that
          showcase your unique strengths and experiences. Our platform ensures
          your application materials are both professional and impactful.
        </p>
        <h2 className="text-2xl font-semibold text-gray-800 mt-4">
          Simplifying the Resume and Cover Letter Process
        </h2>
        <p>
          Crafting resumes and cover letters from scratch can be time-consuming
          and stressful. Talium streamlines this process by offering modern,
          customizable templates and guided creation tools that enable you to
          build compelling documents with ease. Focus on presenting your best
          self while we handle the formatting and optimization.
        </p>
        <h2 className="text-2xl font-semibold text-gray-800 mt-4">
          Empowering Your Career Advancement
        </h2>
        <p>
          Talium&apos;s goal is to empower you in your career journey by
          providing the resources needed to stand out in the competitive job
          market. Whether you&apos;re seeking your first role or advancing in
          your field, our tools are designed to help you confidently showcase
          your skills and achievements.
        </p>
        <h2 className="text-2xl font-semibold text-gray-800 mt-4">
          Combating Rejections with Smarter Applications
        </h2>
        <p>
          Frequent rejections can be discouraging, but Talium helps you apply
          smarter, not harder. By creating tailored, well-optimized resumes and
          cover letters for each opportunity, you can significantly improve your
          visibility to employers and increase your chances of landing
          interviews.
        </p>
        <h2 className="text-2xl font-semibold text-gray-800 mt-4">
          Supporting Your Career Journey
        </h2>
        <p>
          Beyond just tools, Talium is your partner in career success. We
          provide insights and resources to help you refine your application
          strategies and track your progress. With Talium, you have the support
          you need to confidently navigate your career journey.
        </p>
      </div>

      <div className="mt-6">
        <h3 className="text-lg font-semibold text-gray-800">Tags:</h3>
        <div className="flex space-x-2 mt-2">
          <span className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm">
            Resume Builder
          </span>
          <span className="px-3 py-1 bg-green-100 text-green-800 rounded-full text-sm">
            Cover Letters
          </span>
          <span className="px-3 py-1 bg-yellow-100 text-yellow-800 rounded-full text-sm">
            Career Tools
          </span>
        </div>
      </div>
    </div>
  );
};

export default Post2;
