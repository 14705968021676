import React from 'react';

const Post1 = () => {
  return (
    <div className="max-w-4xl mx-auto p-4">
      <img
        src="/images/Image1.png"
        alt="About Talium"
        className="w-full h-64 object-cover rounded-lg shadow-md"
        loading="lazy"
      />

      <div className="mt-6">
        <h1 className="text-4xl font-bold text-gray-800">About Talium</h1>
      </div>

      <div className="mt-6 text-gray-700 space-y-4">
        <p>
          Welcome to Talium! We are dedicated to empowering job seekers by
          simplifying the process of creating ATS-friendly resumes and
          personalized cover letters. Our tools are designed to help you make a
          lasting impression on potential employers.
        </p>
        <h2 className="text-2xl font-semibold text-gray-800 mt-4">
          Our Mission
        </h2>
        <p>
          At Talium, our mission is to equip job seekers with the tools they
          need to create professional, standout resumes and cover letters. We
          believe in making the job application process less daunting, enabling
          you to focus on presenting your best self to employers.
        </p>
        <h2 className="text-2xl font-semibold text-gray-800 mt-4">
          What We Offer
        </h2>
        <ul className="list-disc list-inside">
          <li>
            <strong>ATS-Friendly Resume Builder:</strong> Create resumes that
            are optimized for Applicant Tracking Systems (ATS), ensuring your
            application gets noticed by recruiters and hiring managers.
          </li>
          <li>
            <strong>Customizable Cover Letter Generator:</strong> Easily craft
            cover letters tailored to highlight your unique skills and
            experiences. Personalize each letter to make a memorable impression.
          </li>
          <li>
            <strong>Modern, Professional Templates:</strong> Choose from a
            variety of clean, customizable templates designed to suit your
            personal style and career goals.
          </li>
          <li>
            <strong>Streamlined Application Process:</strong> Save time with
            tools that make creating resumes and cover letters quick, efficient,
            and hassle-free.
          </li>
          <li>
            <strong>Confidence in Your Applications:</strong> With Talium, you
            can be sure that your application materials present you in the best
            possible light, increasing your chances of landing your dream role.
          </li>
        </ul>
        <p>
          By using Talium, you can eliminate the guesswork and stress of
          creating application materials, focusing instead on showcasing your
          talents and advancing your career.
        </p>
      </div>

      <div className="mt-6">
        <h3 className="text-lg font-semibold text-gray-800">Tags:</h3>
        <div className="flex space-x-2 mt-2">
          <span className="px-3 py-1 bg-blue-100 text-blue-800 rounded-full text-sm">
            Resume Builder
          </span>
          <span className="px-3 py-1 bg-green-100 text-green-800 rounded-full text-sm">
            Cover Letters
          </span>
          <span className="px-3 py-1 bg-yellow-100 text-yellow-800 rounded-full text-sm">
            Career Tools
          </span>
        </div>
      </div>
    </div>
  );
};

export default Post1;
