import { StyleSheet } from '@react-pdf/renderer';

export const tailwindColors = {
  blue400: '#60A5FA',
  gray700: '#374151',
  gray500: '#6B7280',
  white: '#FFFFFF',
};

export const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 24,
    fontSize: 12,
    fontFamily: 'Helvetica',
    color: tailwindColors.gray700,
    backgroundColor: tailwindColors.white,
    borderRadius: 4,
  },
  section: {
    marginBottom: 10,
  },
  heading: {
    fontSize: 14,
    fontWeight: 'bold',
    color: tailwindColors.blue400,
    marginBottom: 4,
    borderBottomWidth: 2,
    borderBottomColor: tailwindColors.blue400,
    borderBottomStyle: 'solid',
  },
  textBold: { fontWeight: 'bold' },
  textItalic: { fontStyle: 'italic' },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 2,
  },
  dateContainer: {
    width: 120,
    textAlign: 'right',
    flexShrink: 0,
  },
});
