// ModernResume.tsx

import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../../../middleware/AuthContext';
import SignIn from '../../../SignIn/SignIn';
import api from '../../../../services/api';

import { UserProfile } from '../../../../models/profile';
import {
  Page,
  Text,
  View,
  Document,
  PDFDownloadLink,
} from '@react-pdf/renderer';
import { getCityState, startToEndDate } from '../helpers';
import { tailwindColors, styles } from './ModernResumeHelpers';

const ModernResume: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(!isAuthenticated);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);

  const descriptionRefs = useRef<(HTMLTextAreaElement | null)[]>([]);
  useEffect(() => {
    if (!isAuthenticated) {
      setIsModalOpen(true);
      return;
    }

    const fetchUserProfile = async () => {
      try {
        const { data } = await api.get('/user/getuser');
        setUserProfile(data);
      } catch (error) {
        console.error('Failed to fetch user profile', error);
      }
    };
    fetchUserProfile();
  }, [isAuthenticated]);

  useEffect(() => {
    if (!userProfile) return;
    descriptionRefs.current.forEach((textarea) => {
      if (!textarea) return;
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    });
  }, [userProfile]);

  if (!isAuthenticated) {
    return isModalOpen ? (
      <SignIn onClose={() => setIsModalOpen(false)} />
    ) : null;
  }

  if (!userProfile) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  const handleInputChange = (field: keyof UserProfile, value: string) => {
    setUserProfile((prev) => (prev ? { ...prev, [field]: value } : prev));
  };

  const handleNestedInputChange = <
    T extends 'work_experience' | 'education' | 'skills' | 'certifications',
  >(
    section: T,
    index: number,
    field: keyof Required<UserProfile>[T][number],
    value: string
  ) => {
    if (!userProfile) return;
    const currentSection = userProfile[section];
    if (Array.isArray(currentSection)) {
      const updated = [...currentSection];
      updated[index] = { ...updated[index], [field]: value };
      setUserProfile({ ...userProfile, [section]: updated });
    }
  };

  const MyDocument = ({ profile }: { profile: UserProfile }) => (
    <Document>
      <Page style={styles.page} size="A4">
        {/* Header */}
        <View style={styles.section}>
          <Text
            style={{
              fontSize: 20,
              fontWeight: 'bold',
              textAlign: 'center',
              marginBottom: 8,
              color: tailwindColors.gray700,
            }}
          >
            {profile.name}
          </Text>
          <Text style={{ textAlign: 'center', color: tailwindColors.gray700 }}>
            {getCityState(profile)} | {profile.email} | {profile.linkedin} |{' '}
            {profile.phone}
          </Text>
        </View>

        {/* Education */}
        {profile.education?.length ? (
          <View style={styles.section}>
            <Text style={styles.heading}>Education</Text>
            {profile.education.map((edu, i) => (
              <View key={i} style={styles.row}>
                <View style={{ flex: 1 }}>
                  <Text style={styles.textBold}>{edu.school}</Text>
                  <Text>
                    {(edu.degree || '') + (edu.field ? ` in ${edu.field}` : '')}
                  </Text>
                </View>
                <Text style={styles.dateContainer}>
                  {startToEndDate(edu.start || '', edu.end || '')}
                </Text>
              </View>
            ))}
          </View>
        ) : null}

        {/* Work Experience */}
        {profile.work_experience?.length ? (
          <View style={styles.section}>
            <Text style={styles.heading}>Work Experience</Text>
            {profile.work_experience.map((work, i) => (
              <View key={i} style={{ marginBottom: 10 }}>
                <View style={styles.row}>
                  <Text style={styles.textBold}>{work.company}</Text>
                  <Text style={styles.dateContainer}>
                    {startToEndDate(work.start || '', work.end || '')}
                  </Text>
                </View>
                <Text style={styles.textItalic}>{work.jobTitle}</Text>
                <Text>{work.description}</Text>
              </View>
            ))}
          </View>
        ) : null}

        {/* Certifications */}
        {profile.certifications?.length ? (
          <View style={styles.section}>
            <Text style={styles.heading}>Certifications</Text>
            {profile.certifications.map((cert, i) => (
              <View key={i} style={styles.row}>
                <View style={{ flex: 1 }}>
                  <Text style={styles.textBold}>
                    {cert.issuingOrganization}
                  </Text>
                  <Text>{cert.name}</Text>
                </View>
                <Text style={styles.dateContainer}>
                  {startToEndDate(
                    cert.issueDate || '',
                    cert.expirationDate || ''
                  )}
                </Text>
              </View>
            ))}
          </View>
        ) : null}

        {/* Skills */}
        {profile.skills?.length ? (
          <View style={styles.section}>
            <Text style={styles.heading}>Skills</Text>
            {profile.skills.map((skill, i) => (
              <Text key={i}>
                {skill.name} - {skill.proficiency}
              </Text>
            ))}
          </View>
        ) : null}
      </Page>
    </Document>
  );

  return (
    <div>
      <div className="my-2 bg-white rounded-md shadow-md max-w-3xl mx-auto px-6 py-4">
        {/* Editable Header */}
        <div className="text-center mb-4">
          <input
            type="text"
            className="text-2xl font-bold my-2 w-full text-center"
            placeholder="Your Name"
            value={userProfile.name || ''}
            onChange={(e) => handleInputChange('name', e.target.value)}
          />
          <div className="tag-line text-sm mx-auto flex justify-between items-center">
            <input
              type="text"
              className="p-1 rounded-md text-center"
              placeholder="City, State"
              value={getCityState(userProfile)}
              onChange={(e) => {
                const [city, state] = e.target.value
                  .split(',')
                  .map((s) => s.trim());
                handleInputChange('city', city || '');
                handleInputChange('state', state || '');
              }}
            />
            <span className="text-gray-500">|</span>
            <input
              type="text"
              className="p-1 rounded-md text-center"
              placeholder="email@example.com"
              value={userProfile.email || ''}
              onChange={(e) => handleInputChange('email', e.target.value)}
            />
            <span className="text-gray-500">|</span>
            <input
              type="text"
              className="p-1 rounded-md text-center"
              placeholder="LinkedIn URL"
              value={userProfile.linkedin || ''}
              onChange={(e) => handleInputChange('linkedin', e.target.value)}
            />
            <span className="text-gray-500">|</span>
            <input
              type="text"
              className="p-1 rounded-md text-left"
              placeholder="(123) 456-7890"
              value={userProfile.phone || ''}
              onChange={(e) => handleInputChange('phone', e.target.value)}
            />
          </div>
        </div>

        {/* Education Section */}
        <div className="mt-2">
          <div className="relative">
            <h2 className="text-lg font-bold inline-block z-10 bg-white pr-2 text-blue-400">
              Education
            </h2>
            <div className="underline absolute transform -translate-y-1/2 left-0 right-0 border-t border-blue-400 border-2"></div>
          </div>
          <div className="mt-1 text-gray-700">
            {userProfile.education?.map((edu, index) => (
              <div key={index}>
                <input
                  type="text"
                  className="w-full my-1 rounded-md"
                  placeholder="Enter school/university name"
                  value={edu.school || ''}
                  onChange={(e) =>
                    handleNestedInputChange(
                      'education',
                      index,
                      'school',
                      e.target.value
                    )
                  }
                />
                <div className="flex justify-between space-x-4">
                  <input
                    type="text"
                    className="flex-1 rounded-md"
                    placeholder="e.g., 'B.Sc in Computer Science'"
                    value={`${edu.degree || ''}${edu.field ? ` in ${edu.field}` : ''}`}
                    onChange={(e) => {
                      const [degree, field] = e.target.value.split(' in ');
                      handleNestedInputChange(
                        'education',
                        index,
                        'degree',
                        degree || ''
                      );
                      handleNestedInputChange(
                        'education',
                        index,
                        'field',
                        field || ''
                      );
                    }}
                  />
                  <input
                    type="text"
                    className="w-1/4 rounded-md text-right pr-2"
                    placeholder="Grad Year"
                    value={startToEndDate(edu.start || '', edu.end || '')}
                    readOnly
                    disabled
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Work Experience */}
        <div>
          <div className="relative">
            <h2 className="text-lg font-bold inline-block z-10 bg-white pr-2 text-blue-400">
              Work Experience
            </h2>
            <div className="underline absolute transform -translate-y-1/2 left-0 right-0 border-t border-blue-400 border-2"></div>
          </div>
          <div className="mt-1 text-gray-700">
            {userProfile.work_experience?.map((work, index) => (
              <div key={index} className="mb-2">
                <input
                  type="text"
                  className="w-full my-1 rounded-md font-bold"
                  placeholder="Enter company name"
                  value={work.company || ''}
                  onChange={(e) =>
                    handleNestedInputChange(
                      'work_experience',
                      index,
                      'company',
                      e.target.value
                    )
                  }
                />
                <div className="flex justify-between space-x-4">
                  <input
                    type="text"
                    className="flex-1 rounded-md italic"
                    placeholder="Enter job title"
                    value={work.jobTitle || ''}
                    onChange={(e) =>
                      handleNestedInputChange(
                        'work_experience',
                        index,
                        'jobTitle',
                        e.target.value
                      )
                    }
                  />
                  <input
                    type="text"
                    className="w-1/4 rounded-md text-right pr-2"
                    placeholder="Duration"
                    value={startToEndDate(work.start || '', work.end || '')}
                    readOnly
                    disabled
                  />
                </div>
                <textarea
                  ref={(el) => (descriptionRefs.current[index] = el)}
                  className="w-full py-1 rounded-md mt-1 resize-none"
                  placeholder="Describe your responsibilities and achievements"
                  value={work.description || ''}
                  onInput={(e) => {
                    const target = e.target as HTMLTextAreaElement;
                    target.style.height = 'auto';
                    target.style.height = `${target.scrollHeight}px`;
                    handleNestedInputChange(
                      'work_experience',
                      index,
                      'description',
                      target.value
                    );
                  }}
                  style={{
                    overflow: 'hidden',
                    height: 'auto',
                    minHeight: '3rem',
                  }}
                />
              </div>
            ))}
          </div>
        </div>

        {/* Certifications */}
        <div className="mb-2">
          <div className="relative">
            <h2 className="text-lg font-bold inline-block z-10 bg-white pr-2 text-blue-400">
              Certifications
            </h2>
            <div className="underline absolute transform -translate-y-1/2 left-0 right-0 border-t border-blue-400 border-2"></div>
          </div>
          <div className="mt-1 text-gray-700">
            {userProfile.certifications?.map((cert, index) => (
              <div key={index} className="mb-4">
                <input
                  type="text"
                  className="w-full rounded-md mb-2"
                  placeholder="Issuing organization"
                  value={cert.issuingOrganization || ''}
                  onChange={(e) =>
                    handleNestedInputChange(
                      'certifications',
                      index,
                      'issuingOrganization',
                      e.target.value
                    )
                  }
                />
                <div className="flex justify-between items-center space-x-4">
                  <input
                    type="text"
                    className="flex-1 rounded-md text-left"
                    placeholder="Certification name"
                    value={cert.name || ''}
                    onChange={(e) =>
                      handleNestedInputChange(
                        'certifications',
                        index,
                        'name',
                        e.target.value
                      )
                    }
                  />
                  <input
                    type="text"
                    className="w-1/3 rounded-md text-right"
                    placeholder="Dates"
                    value={startToEndDate(
                      cert.issueDate || '',
                      cert.expirationDate || ''
                    )}
                    readOnly
                    disabled
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Skills */}
        <div className="mb-2">
          <div className="relative">
            <h2 className="text-lg font-bold inline-block z-10 bg-white pr-2 text-blue-400">
              Skills
            </h2>
            <div className="underline absolute transform -translate-y-1/2 left-0 right-0 border-t border-blue-400 border-2"></div>
          </div>
          <div className="mt-1 text-gray-700">
            {userProfile.skills?.map((skill, index) => (
              <div key={index} className="flex justify-between space-x-4">
                <input
                  type="text"
                  className="flex-1 rounded-md"
                  placeholder="Enter skill"
                  value={skill.name || ''}
                  onChange={(e) =>
                    handleNestedInputChange(
                      'skills',
                      index,
                      'name',
                      e.target.value
                    )
                  }
                />
                <input
                  type="text"
                  className="w-1/4 rounded-md text-right pr-2"
                  placeholder="Proficiency"
                  value={skill.proficiency || ''}
                  onChange={(e) =>
                    handleNestedInputChange(
                      'skills',
                      index,
                      'proficiency',
                      e.target.value
                    )
                  }
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* PDF Download Link */}
      <PDFDownloadLink
        document={<MyDocument profile={userProfile} />}
        fileName="resume.pdf"
      >
        <button className="my-2 ml-[25%] bg-green-500 text-white py-2 px-6 rounded">
          Download Resume
        </button>
      </PDFDownloadLink>
    </div>
  );
};

export default ModernResume;
