import { UserProfile } from '../../../models/profile';

export const getCityState = (userProfile: UserProfile) => {
  const city = userProfile.city || '';
  const state = userProfile.state || '';
  return `${city}${city && state ? ', ' : ''}${state}`;
};

export const startToEndDate = (start: string, end: string) => {
  if (start && end) {
    return `${start.split('-')[1]}/${start.split('-')[0]} - ${end.split('-')[1]}/${end.split('-')[0]}`;
  } else if (start) {
    return `${start.split('-')[1]}/${start.split('-')[0]} - Present`;
  } else if (end) {
    return `Until ${end.split('-')[1]}/${end.split('-')[0]}`;
  } else {
    return '';
  }
};
