import React from 'react';

interface NumberIconProps {
  number: number;
}

const NumberIcon: React.FC<NumberIconProps> = ({ number }) => (
  <div
    className="mr-6 flex items-center justify-center w-24 h-24 bg-blue-500 border-2 border-gray-300 text-white rounded-full text-4xl font-bold hover:scale-110 hover:bg-blue-600 transition-transform duration-300"
    aria-label={`Section ${number}`}
  >
    {number}
  </div>
);

export default NumberIcon;
