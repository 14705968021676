import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { UserProfile } from '../../models/profile';
import SignIn from '../SignIn/SignIn';
import { useAuth } from '../../middleware/AuthContext';
import api from '../../services/api';

const Resume: React.FC = () => {
  const { isAuthenticated } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(!isAuthenticated);
  const [userProfile, setUserProfile] = useState<UserProfile | undefined>();

  useEffect(() => {
    if (!isAuthenticated) {
      setIsModalOpen(true);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const { data: userProfileData } = await api.get('/user/getuser');
        setUserProfile(userProfileData);
      } catch (error) {
        console.error('Failed to fetch user profile', error);
      }
    };

    if (isAuthenticated) {
      fetchUserProfile();
    }
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    return isModalOpen ? (
      <SignIn onClose={() => setIsModalOpen(false)} />
    ) : null;
  }

  if (!userProfile) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  const resumeFormats = [
    {
      name: 'Modern Resume',
      route: '/resume/modern_resume',
      imageSrc: '/images/modernresume-preview.jpg',
    },
    {
      name: 'Some Other Resume',
      route: '/resume/format2',
      imageSrc: '/images/format2-preview.jpg',
    },
    {
      name: 'Another Resume',
      route: '/resume/format3',
      imageSrc: '/images/format3-preview.jpg',
    },
    {
      name: 'Professional Resume',
      route: '/resume/modern_resume',
      imageSrc: '/images/modernresume-preview.jpg',
    },
    {
      name: 'Some Other Resume2',
      route: '/resume/format2',
      imageSrc: '/images/format2-preview.jpg',
    },
    {
      name: 'Another Resume2',
      route: '/resume/format3',
      imageSrc: '/images/format3-preview.jpg',
    },
  ];

  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {resumeFormats.map((format, index) => (
          <Link to={format.route} key={index} className="group block">
            <img
              src={format.imageSrc}
              alt={format.name}
              className="w-full h-auto rounded shadow group-hover:shadow-lg transition-shadow"
            />
            <h3 className="mt-2 text-center font-semibold">{format.name}</h3>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Resume;
